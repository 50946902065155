import {
  FormInput,
  FormButton,
  FormMultiSelect,
  FormSelect,
  FormCheckBox,
} from "../../../commun";
import { useFormik } from "formik";
import { templateSchema } from "../../../../validations/template-validation";
import {
  countryList,
  currencies_list,
  types,
} from "../../../../core/constants";
import { useEffect, useState } from "react";
import {
  useCreatePredefinedOffer,
  useUpdatePredefinedOffer,
} from "../../../../services/api";
import { useNavigate, useLocation } from "react-router-dom";
import { CurrenciesForm } from "../../components/currencies-form";
import { Currency } from "../../../../core/types";
import { ConfirmPopup } from "../../../commun/confirm-popup";

export const ManageTemplateForm = () => {
  const navigate = useNavigate();
  const [currencies, setCurrencies] = useState<Array<any>>([]);
  const [newSections, setNewSections] = useState<Array<number | undefined>>([]);
  const [showPopUp, setShowPopUP] = useState<boolean>(false);
  const { state } = useLocation();
  const {
    mutateAsync,
    isPending,
    isSuccess: isSuccessCreate,
    isError: isErrorCreate,
  } = useCreatePredefinedOffer();
  const {
    mutateAsync: updateAsync,
    isPending: isPendingUpdate,
    isSuccess: isSuccessUpdate,
    isError: isErrorupdate,
  } = useUpdatePredefinedOffer();

  useEffect(() => {
    if (state?.template) {
      let newCurrencies: Array<Currency> = [];
      state?.template.currency_params.map(
        (currency: Currency, index: number) => {
          delete currency.created_at;
          delete currency.currencyable_id;
          delete currency.currencyable_type;
          delete currency.updated_at;
          newCurrencies.push({ id: index + 1, ...currency });
        }
      );
      setCurrencies([...newCurrencies]);
    }
  }, []);

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: state?.template
      ? {
          name: state?.template.name,
          approval_fee: state?.template.approval_fee,
          failure_fee: state?.template.failure_fee,
          refund_fee: state?.template.refund_fee,
          chargeback_fee: state?.template.chargeback_fee,
          type: state?.template.type,
          daily_limit: state?.template.daily_limit,
          global_min_transaction: state?.template.global_min_transaction,
          global_max_transaction: state?.template.global_max_transaction,
          kyc_requirement: state?.template.kyc_requirement,
        }
      : {
          name: "",
          approval_fee: 0,
          failure_fee: 0,
          refund_fee: 0,
          chargeback_fee: 0,
          type: "card",
          daily_limit: 0,
          global_min_transaction: 0,
          global_max_transaction: 0,
          kyc_requirement: true,
        },
    validationSchema: templateSchema,
    onSubmit: (values) => {
      if (currencies.length > 0) {
        let newCurrencie = currencies.map((currency) => {
          delete currency.id;
          return currency;
        });

        if (state?.template) {
          const payload = {
            id: state.template.id,
            metadata: null,
            ...values,
            currencies: newCurrencie,
          };
          updateTemplate(payload);
        } else {
          const payload = {
            metadata: null,
            ...values,
            currencies: newCurrencie,
          };
          createTemplate(payload);
        }
      } else {
        setShowPopUP(true);
      }
    },
  });

  const createTemplate = async (values: any) => {
    await mutateAsync(values, {
      onSuccess: (response) => {
        // onCancel();
        navigate(-1);
      },
      onError: (error) => {
        // console.log("from login", error);
      },
    }).catch((error) => console.log());
  };
  const updateTemplate = async (values: any) => {
    await updateAsync(values, {
      onSuccess: (response) => {
        // onCancel();
        navigate(-1);
      },
      onError: (error) => {
        // console.log("from login", error);
      },
    }).catch((error) => console.log());
  };

  const currenciesUpdate = (item: any, index: number) => {
    let newItems = currencies;
    let newItem = { ...newItems[index] };
    newItem = item;
    newItems[index] = newItem;
    setCurrencies([...newItems]);
    setNewSections((oldValues) =>
      oldValues.filter((section_id) => section_id !== item.id)
    );
  };
  const addCurrencySection = () => {
    let last_id: number | undefined = 0;
    if (currencies.length > 0) {
      last_id = currencies[currencies.length - 1].id;
    } else {
      last_id = 1;
    }

    let newCurrency: Currency = {
      id: last_id && last_id + 1,
      code: "",
      type: "",
      mdr: 0,
      rolling_reserve_amount: 0,
      rolling_reserve_percent: 0,
      rolling_reserve_duration: 0,
      settlement_fee: 0,
      settlement_cycle: 0,
    };
    setCurrencies([...currencies, newCurrency]);
    setNewSections([...newSections, newCurrency.id]);
  };
  const removeCurrency = (id: number | undefined) => {
    setCurrencies((oldValues) =>
      oldValues.filter((currency) => currency.id !== id)
    );

    setNewSections((oldValues) =>
      oldValues.filter((section_id) => section_id !== id)
    );
  };
  return (
    <div className=" flex flex-col gap-y-4 w-full items-center y-global-bg">
      <form
        className="flex flex-col gap-y-4 min-w-[300px]  w-full max-w-[460px] bg-white p-5 rounded-lg "
        onSubmit={handleSubmit}
      >
        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"text"}
          label={"Template's name"}
          id="name"
          value={values.name}
          errors={errors.name}
          touched={touched.name}
        />
        <FormSelect
          placeholder={"Select an option"}
          options={types}
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"text"}
          label={"Type"}
          id="type"
          value={values.type}
          errors={errors.type}
          touched={touched.type}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Min transaction"}
          id="global_min_transaction"
          value={values.global_min_transaction}
          errors={errors.global_min_transaction}
          touched={touched.global_min_transaction}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Limit size per transaction"}
          id="global_max_transaction"
          value={values.global_max_transaction}
          errors={errors.global_max_transaction}
          touched={touched.global_max_transaction}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Approved Transaction Fee"}
          id="approval_fee"
          value={values.approval_fee}
          errors={errors.approval_fee}
          touched={touched.approval_fee}
        />
        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Declined Transaction Fee"}
          id="failure_fee"
          value={values.failure_fee}
          errors={errors.failure_fee}
          touched={touched.failure_fee}
        />
        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Refund Fee (Per transaction)"}
          id="refund_fee"
          value={values.refund_fee}
          errors={errors.refund_fee}
          touched={touched.refund_fee}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Chargeback Fees - Dispute (Per dispute)"}
          id="chargeback_fee"
          value={values.chargeback_fee}
          errors={errors.chargeback_fee}
          touched={touched.chargeback_fee}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Daily limit"}
          id="daily_limit"
          value={values.daily_limit}
          errors={errors.daily_limit}
          touched={touched.daily_limit}
        />
        <FormCheckBox
          title={"Required kyc files"}
          value={values.kyc_requirement}
        />
      </form>

      {/* <div className="mt-3 font-bold text-neutral-900 text-lg">
        Buy Rates for Card Processing
      </div> */}
      {currencies.map((currency: any, index: number) => (
        <CurrenciesForm
          onHandleSubmit={(item: any, i: number) => currenciesUpdate(item, i)}
          key={currency.id}
          index={index}
          currency={currency}
          onRemoveItem={(i) => removeCurrency(i)}
          // setDisabledButton={()=> setIsDisabled(false)}
        />
      ))}

      <div className="flex min-w-[300px]  w-full max-w-[460px]">
        <FormButton
          type={"submit"}
          title="Add New Currency"
          isLoding={false}
          disabled={false}
          onClick={addCurrencySection}
        />
      </div>

      <div className="min-w-[300px]  w-full max-w-[460px]">
        {state?.template ? (
          <>
            <FormButton
              type={"submit"}
              onClick={handleSubmit}
              title="Update"
              isLoding={isPendingUpdate}
              disabled={newSections.length > 0 || isPendingUpdate}
            />
          </>
        ) : (
          <FormButton
            type={"submit"}
            onClick={handleSubmit}
            title="Create Offer"
            isLoding={isPending}
            disabled={newSections.length > 0 || isPending}
          />
        )}
      </div>
      {showPopUp && (
        <ConfirmPopup
          title="Please add at least one currency section"
          confirmButtonTitle={"Got it"}
          onConfirm={() => setShowPopUP(false)}
        />
      )}
    </div>
  );
};
