import { getItem, removeItem, setItem } from "../storage";

const AUTH = "auth";

export type AuthType = {
  //to change
  createdAt: Date;
  userId: string;
  expire: Date;
};

export const getAuth = () => getItem<any>(AUTH);
export const removeAuth = () => removeItem(AUTH);
export const setAuth = (value: any) => setItem<any>(AUTH, value);
