import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { DocumentsListTemplate } from "../../modules/kyb-reviews/templates/documents-list";

export const DocumentsList = () => {
  return (
    <div className=" md:py-10 py-3">
      <div className="flex items-center gap-x-3 mb-6">
        <Link
          to={"/kyb-reviews"}
          className=" bg-neutral-100 h-[30px] text-neutral-500 flex items-center justify-center rounded aspect-square"
        >
          <IoIosArrowBack size={18} />
        </Link>
        <div className=" font-bold text-2xl xs:text-4xl">KYB Review</div>
      </div>
      <DocumentsListTemplate />
    </div>
  );
};
