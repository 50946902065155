import { SearchInput } from "../../../commun/search-input";
import { CiCalendarDate } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
import { CustomCalendar } from "../../../commun/calendar";

export const FilterSection = ({
  search,
  filterData,
  date,
  setDate,
}: {
  search: string;
  date: Date | undefined;
  setDate: (date: any) => void;
  filterData: (e: any) => void;
}) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const pickDate = (date: any) => {
    setDate(date);
    setOpenCalendar(false);
  };

  return (
    <div className="flex w-full flex-wrap gap-y-2 justify-between gap-x-2 items-center">
      {openCalendar && (
        <CustomCalendar
          date={date}
          setOpenCalendar={setOpenCalendar}
          pickDate={pickDate}
          setDate={setDate}
        />
      )}
      <div className="flex-1 min-w-[300px]">
        <SearchInput
          placeholder="Search by merchant"
          value={search}
          onhandleChange={(e: any) => filterData(e)}
        />
      </div>
      <button
        onClick={() => setOpenCalendar(true)}
        className=" border gap-x-1 rounded py-2 text-sm items-center border-neutral-300 px-2 h-full flex text-neutral-500"
      >
        <CiCalendarDate size={17} />
        <div>Pick a date</div>
      </button>
      <button className=" border gap-x-1 rounded py-2 text-sm items-center border-neutral-300 px-3 h-full flex text-neutral-500">
        <div>Status</div>
        <IoIosArrowDown size={15} />
      </button>
      <button className=" border-none gap-x-1 rounded py-2 text-sm items-center bg-primary-500  px-3 h-full flex text-white">
        <div>Export</div>
      </button>
    </div>
  );
};
