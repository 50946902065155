import { ReactNode } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { Spinner } from "../../../commun";

export const DocumentModal = ({
  title,
  children,
  onCancel,
  onApprove,
  isPending,
  state,
}: {
  title: string;
  children?: ReactNode;
  onCancel: () => void;
  onApprove: (state: string) => void;
  state: string;
  isPending: boolean;
}) => {
  return (
    <div className=" fixed top-0  left-0 h-full w-full flex justify-center items-center bg-black/40">
      <div className=" bg-white shadow-lg shadow-black/20 p-4 ">
        <div className="flex w-full justify-between items-center">
          <div className="font-bold text-neutral-900 text-lg">{title}</div>
          <button onClick={onCancel}>
            <IoCloseOutline size={18} className=" text-neutral-900" />
          </button>
        </div>
        <div className="mt-4 flex w-full justify-center">{children}</div>

        <div className="mt-6 flex justify-end gap-x-3">
          <button
            onClick={() => onApprove("rejected")}
            disabled={isPending || state === "rejected"}
            className={` ${state === "rejected" ? "bg-red-600/50" : "bg-red-600"}  text-white flex items-center gap-x-1 rounded py-2 justify-center w-36`}
          >
            {isPending ? (
              <Spinner />
            ) : (
              <>
                <IoCloseOutline size={18} className=" " />
                Reject
              </>
            )}
          </button>
          <button
            disabled={isPending || state === "approved"}
            onClick={() => onApprove("approved")}
            className={` ${state === "approved" ? "bg-primary-500/50" : "bg-primary-500"}  text-white flex items-center gap-x-1 rounded py-2 justify-center w-36`}
          >
            {isPending ? (
              <Spinner />
            ) : (
              <>
                <FiCheckCircle size={18} className=" " />
                Approve
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
