import { FaCheck } from "react-icons/fa6";
import { OfferSentTemplate } from "../../modules/offer-generation/templates/offer-sent";

export const OfferSent = () => {
  return (
    <div className=" flex items-center justify-start flex-col w-full">
      <OfferSentTemplate />
    </div>
  );
};
