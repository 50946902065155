import { Currency } from "../../../../core/types";

export const OfferCurrencyCard = ({ currency }: { currency: Currency }) => {
  return (
    <>
      <div className="py-1 mt-2">
        <div className=" bg-primary-500 py-1 rounded-t-lg capitalize text-white  font-semibold text-xl text-center">
          {currency.type}
        </div>
        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">Currency</div>
          <div className={` text-sm text-neutral-600`}>{currency.code}</div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">MDR</div>
          <div className={` text-sm text-neutral-600`}>{currency.mdr}</div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Rolling reserve amount
          </div>
          <div className={` text-sm text-neutral-600`}>
            {currency.rolling_reserve_amount}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Rolling reserve percent
          </div>
          <div className={` text-sm text-neutral-600`}>
            %{currency.rolling_reserve_percent}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Rolling reserve duration
          </div>
          <div className={` text-sm text-neutral-600`}>
            {currency.rolling_reserve_duration}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Settlement fee
          </div>
          <div className={` text-sm text-neutral-600`}>
            {currency.settlement_fee}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Settlement cycle
          </div>
          <div className={` text-sm text-neutral-600`}>
            {currency.settlement_cycle}
          </div>
        </div>
      </div>
    </>
  );
};
