import * as yup from "yup";
export const currenciesSchema = yup.object().shape({
  mdr: yup.number().required("Required"),
  code: yup.string().required("Required"),
  type: yup.string().required("Required"),
  rolling_reserve_amount: yup.number().required("Required"),
  rolling_reserve_percent: yup.number().required("Required"),
  rolling_reserve_duration: yup.number().required("Required"),
  settlement_fee: yup.number().required("Required"),
  settlement_cycle: yup.number().required("Required"),
});
