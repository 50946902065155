import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useQuery } from "@tanstack/react-query";

const getOfferDetails = async (id: number) => {
  const { data } = await client.get(`admin/offers/${id}`, {
    timeout: 2000, // since it can be heavy too
    headers: {
      Authorization: `Bearer ${getItem("auth")}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return data.data.offer;
};

export function useOfferDetails(id: number) {
  return useQuery({
    queryKey: [`get-offer-details-${id}`],
    queryFn: () => getOfferDetails(id),
  });
}
