import { MerchantsTemplate } from "../../modules/marchants/templates/merchants";

export const Home = () => {
  return (
    <div className=" md:py-10 py-3">
      <div className=" font-bold text-2xl xs:text-4xl mb-6">Dashboard</div>
      <MerchantsTemplate />
    </div>
  );
};
