import { AiOutlineExclamationCircle } from "react-icons/ai";
import { LuCheckCircle } from "react-icons/lu";
import { MqrDetailsContainer } from "../../components/mqr-details-container";
import { ConfirmPopup } from "../../../commun/confirm-popup";
import { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

export const MqrDetailsTemplate = ({ mqr }: any) => {
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
  return (
    <div>
      <div className="flex w-full justify-between items-center mb-6">
        <div className=" flex gap-x-1 items-center">
          <Link
            to={"/mqr-review"}
            className=" bg-neutral-100 h-[30px] text-neutral-500 flex items-center justify-center rounded aspect-square"
          >
            <IoIosArrowBack size={18} />
          </Link>
          <div className=" font-bold text-2xl xs:text-4xl  whitespace-nowrap">
            MQR Review
          </div>
        </div>

        <div className=" flex gap-x-1 flex-wrap gap-y-1 justify-end">
          <button className=" rounded  text-neutral-600 bg-neutral-100 md:px-6 px-5 py-2 md:py-[10px] flex justify-center items-center gap-x-1">
            <AiOutlineExclamationCircle size={17} />
            <div className=" text-sm whitespace-nowrap">Request more info</div>
          </button>
          <button
            onClick={() => setOpenConfirmApprove(true)}
            className=" rounded text-white bg-primary-500 md:px-6 px-5 py-2 md:py-[10px] flex justify-center items-center gap-x-2"
          >
            <LuCheckCircle size={15} />
            <div className=" text-sm whitespace-nowrap">Approve MQR</div>
          </button>
        </div>
      </div>

      <MqrDetailsContainer mqr={mqr} />
      {openConfirmApprove && (
        <ConfirmPopup
          title="Are you absolutely sure?"
          description="This action cannot be undone. This will permanently approve the merchant MQR and allow him to move to the next step of the KYB"
          confirmButtonTitle="Approve MQR"
          onConfirm={() => setOpenConfirmApprove(false)}
          onCancel={() => setOpenConfirmApprove(false)}
        />
      )}
    </div>
  );
};
