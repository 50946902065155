export const FormInput = ({
  handleChange,
  errors,
  touched,
  handleBlur,
  label,
  login,
  ...otherProps
}: any) => {
  return (
    <div className="max-w-full">
      {label ? (
        <div className=" text-neutral-900 mb-1 text-sm font-medium">
          {label}
        </div>
      ) : (
        <div className="h-5 mb-1"></div>
      )}
      <input
        onChange={handleChange}
        onBlur={handleBlur}
        {...otherProps}
        className={`h-[40px] w-full border px-4 outline-none rounded  ${
          errors && touched ? "border-red-600" : "border-neutral-300"
        } text-sm`}
      />

      {errors && touched && (
        <div className="text-red-600 text-xs mt-1">{errors}</div>
      )}
    </div>
  );
};
