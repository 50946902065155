import { useOfferDetails } from "../../../../services/api";
import { Spinner } from "../../../commun";
import { OfferForm } from "../offer-form";

export const UpdateOffer = ({ setOpenUpdate, merchantId }: any) => {
  const {
    data: offer,
    isPending,
    refetch,
  } = useOfferDetails(Number(merchantId));
  return (
    <div>
      {offer && (
        <div className="fixed top-0 left-0 h-full w-full bg-black/40 px-3 flex items-center justify-center">
          <OfferForm
            update={true}
            merchant_id={Number(offer.user_id)}
            onConfirm={() => console.log()}
            selected_template={offer}
            onCancel={setOpenUpdate}
          />
        </div>
      )}
    </div>
  );
};
