import { Link } from "react-router-dom";
import { ManageTemplateForm } from "../../modules/offer-generation/templates/manage-template";
import { IoIosArrowBack } from "react-icons/io";

export const TemplateForm = () => {
  return (
    <div className="md:py-10 py-3">
      <div className="flex items-center gap-x-3 mb-6">
        <Link
          to={"/offer-generation"}
          className=" bg-neutral-100 h-[30px] text-neutral-500 flex items-center justify-center rounded aspect-square"
        >
          <IoIosArrowBack size={18} />
        </Link>
        <div className=" font-bold text-2xl xs:text-4xl">Manage template</div>
      </div>
      <div className=" w-full items-center justify-center flex">
        <ManageTemplateForm />
      </div>
    </div>
  );
};
