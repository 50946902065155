import { useSendOffer } from "../../../../services/api";
import { Spinner } from "../../../commun";
export const SendOffer = ({ id, status, refetch }: any) => {
  const { mutateAsync, isPending } = useSendOffer();

  const sendOffer = async () => {
    await mutateAsync(id, {
      onSuccess: (response) => {
        // console.log("response ", response);
        refetch();
      },
      onError: (error) => {
        console.log("from ", error);
      },
    }).catch((error) => console.log());
  };
  return (
    <button
      onClick={sendOffer}
      className=" bg-primary-500 w-20 text-white text-xs font-semibold  rounded py-1 flex justify-center "
    >
      {isPending ? (
        <Spinner />
      ) : status === "document_created" ? (
        "Resend offer"
      ) : (
        "Send offer"
      )}
    </button>
  );
};
