import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const Step = ({
  currentStep,
  step,
}: {
  currentStep: number;
  step: {
    id: number;
    label: string;
    rank: number;
    isDone: boolean;
    handle: string;
    payload?: any;
    payload_name?: any;
  };
}) => {
  const navigate = useNavigate();
  return (
    // <button
    //   onClick={() =>
    //     navigate(
    //       `/`,
    //     //   `${step.handle}`,
    //     //   step.payload && { state: { payload_name: step.payload } }
    //     )
    //   }
    //   className="flex items-center gap-x-2"
    // >
    <div className="flex items-center gap-x-2">
      <div
        className={` ${
          step.isDone
            ? "bg-green-600"
            : // : currentStep === step.rank
              // ? "bg-primary-500"
              "bg-neutral-300"
        } rounded-full h-6 aspect-square flex items-center justify-center`}
      >
        <div className="text-sm text-white">
          {step.isDone ? (
            <FaCheck size={16} color="white" />
          ) : (
            <div className="text-sm text-white">{step.rank}</div>
          )}
        </div>
      </div>
      <div className="text-sm text-neutral-900">{step.label}</div>
    </div>

    // </button>
  );
};
