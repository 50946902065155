import { Table } from "@medusajs/ui";
import { GoEye } from "react-icons/go";
import { FiSend } from "react-icons/fi";
import { Link } from "react-router-dom";
import { DeleteOffer } from "../../components/delete-offer";
import { SendOffer } from "../../components/send-offer";
import { useState } from "react";
import { UpdateOffer } from "../../components/update-offer";
export const OfferReviewTable = ({ items, refetch }: any) => {
  const [merchatIdToUpdate, setMerchatIdToUpdate] = useState<number>(0);
  return (
    <>
      <Table className="">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
              Merchant
            </Table.HeaderCell>

            <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
              Email
            </Table.HeaderCell>
            <Table.HeaderCell className="  whitespace-nowrap font-normal text-neutral-500 border border-neutral-200 text-left px-3">
              Company name
            </Table.HeaderCell>

            <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
              Status
            </Table.HeaderCell>

            <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className=" ">
          {items &&
            items.map(
              (merchant: any) =>
                merchant.mqr && (
                  <Table.Row key={merchant.id}>
                    <Table.Cell className=" whitespace-nowrap py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                      {merchant.name}
                    </Table.Cell>
                    <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                      {merchant.email}
                    </Table.Cell>
                    <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                      {merchant?.mqr?.companyName}
                    </Table.Cell>

                    <Table.Cell className=" py-3 px-3 text-center border border-neutral-200 text-neutral-900 text-sm">
                      {merchant.offer !== null ? (
                        <Link
                          to={`${merchant.id}/${merchant.name}`}
                          className=" flex justify-center center-start"
                        >
                          <span
                            className={`bg-green-600
                     capitalize py-[2px] flex items-center gap-x-2 w-[120px] justify-center font-[600] rounded-full text-white text-xs `}
                          >
                            {merchant.offer.eversign_status ===
                            "offer_generated"
                              ? "pending"
                              : merchant.offer.eversign_status ===
                                  "document_created"
                                ? "Offer sent"
                                : "Offer signed"}
                            {/* {merchant.offer.eversign_status} */}
                            <GoEye size={13} />
                          </span>
                        </Link>
                      ) : (
                        <div className="flex justify-center"></div>
                      )}
                    </Table.Cell>

                    <Table.Cell className=" text-center py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                      {merchant.offer === null ? (
                        <Link
                          to={`offer-templates/${merchant.id}`}
                          className="rounded bg-neutral-200  flex text-neutral-600 py-1 px-2 items-center justify-center gap-x-1"
                        >
                          <FiSend size={15} />
                          <div className=" text-sm font-semibold whitespace-nowrap">
                            Create offer
                          </div>
                        </Link>
                      ) : (
                        <>
                          {merchant.offer?.eversign_status ==
                            "document_signed" ||
                          merchant.offer?.eversign_status ===
                            "email_validation_waived" ? (
                            <></>
                          ) : (
                            <div className=" flex items-center gap-x-4 justify-center min-w-full">
                              <DeleteOffer
                                id={merchant.offer?.id}
                                refetch={refetch}
                              />
                              <SendOffer
                                refetch={refetch}
                                status={merchant.offer.eversign_status}
                                id={merchant.id}
                              />
                              <button
                                onClick={() =>
                                  setMerchatIdToUpdate(Number(merchant.id))
                                }
                                className=" px-3  flex justify-center border-primary-500 border text-primary-500 text-xs rounded py-1 font-semibold"
                              >
                                Update
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
            )}
        </Table.Body>
      </Table>
      {merchatIdToUpdate > 0 && (
        <UpdateOffer
          merchantId={merchatIdToUpdate}
          setOpenUpdate={() => setMerchatIdToUpdate(0)}
        />
      )}
    </>
  );
};
