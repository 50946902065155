import { IoCheckmark } from "react-icons/io5";
import { RiCloseLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import getDate from "../../../../core/utils/get-date";
import { GoEye } from "react-icons/go";

export const MerchantDetailsTemplate = ({ merchant }: any) => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" flex flex-col mt-5 gap-y-4">
        <div className=" rounded-lg border border-neutral-200 w-full px-4 py-3 flex flex-col gap-y-1 items-start">
          <div className=" flex items-center gap-x-2">
            <div className=" w-12 h-12 text-xl text-neutral-600 font-semibold flex justify-center items-center rounded-full border border-neutral-200">
              {merchant.name[0]}
            </div>
            <div className="flex flex-col ">
              <div className=" text-neutral-600">{merchant.name}</div>
              <div className=" text-xs text-neutral-600">website.com</div>
            </div>
          </div>
        </div>

        <div className=" rounded-lg border border-neutral-200 w-full px-4 py-3 flex flex-col gap-y-3 items-start">
          <div className="font-bold text-neutral-900">Personal information</div>

          <div className="flex w-full xs:gap-x-20 gap-x-0 xs:justify-normal justify-between items-end">
            <div className=" text-sm flex flex-col text-neutral-600 xs:min-w-[200px] ">
              <div className="">Full name</div>
              <div className="  font-semibold">{merchant.name}</div>
            </div>
            <div className=" text-sm flex flex-col text-neutral-600  ">
              <div className="">Created on</div>
              <div className="  font-semibold">
                {getDate(merchant.created_at)}
              </div>
            </div>
          </div>

          <div className="flex w-full xs:gap-x-20 gap-x-0 xs:justify-normal justify-between items-end">
            <div className=" text-sm flex flex-col text-neutral-600 xs:min-w-[200px] ">
              <div className="">Email</div>
              <div className="  font-semibold">{merchant.email}</div>
            </div>
            <div className=" text-sm">
              {merchant.email_verified_at ? (
                <div className=" flex gap-x-1 items-center text-green-600">
                  <div className=" ">Verified</div>
                  <IoCheckmark size={17} />
                </div>
              ) : (
                <div className=" flex gap-x-1 items-center text-red-500">
                  <div className=" ">Not verified</div>
                  <RiCloseLine size={17} />
                </div>
              )}
            </div>
          </div>

          <div className="flex w-full xs:gap-x-20 gap-x-0 xs:justify-normal justify-between items-end">
            <div className=" text-sm flex flex-col text-neutral-600 xs:min-w-[200px] ">
              <div className="">MQR status</div>
              <div className="  font-semibold">
                {merchant.mqr?.id ? (
                  <div className=" flex flex-col justify-start items-start">
                    <div className=" text-primary-500 ">Submitted</div>
                    <Link
                      to={`/mqr-review/mqr-details/${merchant.id}`}
                      className=" text-primary-500 flex items-center justify-end gap-x-1 "
                    >
                      <GoEye size={13} className=" " />
                      <div>View</div>
                    </Link>
                  </div>
                ) : (
                  <div>Not submitted</div>
                )}
              </div>
            </div>

            <div className=" text-sm flex flex-col text-neutral-600 ">
              <div className="">KYB status</div>
              <div className="  font-semibold">
                {merchant.files.length > 0 ? (
                  <div>
                    <div className=" text-primary-500">Submitted</div>

                    <button
                      className=" text-primary-500 text-sm flex gap-x-4 items-center"
                      onClick={() =>
                        navigate("/kyb-reviews/documents", {
                          state: { merchant: merchant },
                        })
                      }
                    >
                      <GoEye size={15} />
                      View
                    </button>
                  </div>
                ) : (
                  <div>Not submitted</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
