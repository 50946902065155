import { FormInput, FormButton, FormCheckBox } from "../commun";
import { useFormik } from "formik";
import { loginSchema } from "../../validations/login-validation";
// import { Recaptcha } from "../common/recaptcha";
import { useSignIn } from "../../services/api";
import { useState } from "react";
import { ConfirmPopup } from "../commun/confirm-popup";

export const LoginForm = () => {
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { mutateAsync, isPending, isError, error } = useSignIn();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      login(values);
    },
  });
  const login = async (data: any) => {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    await mutateAsync(formData, {
      onSuccess: (response) => {
        console.log("response from login form", response);
      },
      onError: (error) => {
        console.log("from login", error);
      },
    }).catch((error) => {
      setErrorMessage("Please verify email / password are matching");
      setShowError(true);
    });
  };
  return (
    <form
      className="flex flex-col gap-y-4 min-w-[300px] md:min-w-[400px]"
      onSubmit={handleSubmit}
    >
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"email"}
        label={"Email"}
        placeholder={"name@example.com"}
        id="email"
        value={values.email}
        errors={errors.email}
        touched={touched.email}
      />
      <FormInput
        label={"Password"}
        handleBlur={handleBlur}
        handleChange={handleChange}
        type="password"
        placeholder="******"
        id="password"
        name="password"
        value={values.password}
        errors={errors.password}
        touched={touched.password}
        login={true}
      />
      <FormCheckBox title="Remember me" />
      {/* <Recaptcha /> */}
      <FormButton
        type={"submit"}
        title="Log In"
        isLoding={isPending}
        disabled={isPending}
      />
      {showError && (
        <ConfirmPopup
          onConfirm={() => setShowError(false)}
          confirmButtonTitle="Got it"
          title={errorMessage}
        />
      )}
    </form>
  );
};
