import { useState } from "react";
import { TemplateCard } from "../../components/offer-template-card";
import { LuWand } from "react-icons/lu";
import { FiSend } from "react-icons/fi";
import { ConfirmPopup } from "../../../commun/confirm-popup";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../../../commun";
import { usePredefinedOffers } from "../../../../services/api/predefined-offers";
import { SelectedOfferrCard } from "../../components/selected-offer-card";
import { useLocation } from "react-router-dom";
import { OfferForm } from "../../components/offer-form";

export const OfferGenerationTemplate = ({ merchantId }: any) => {
  const { state } = useLocation();
  const { data, isLoading, isSuccess, isError, refetch } =
    usePredefinedOffers();

  const [isSelected, setIsSelected] = useState<any>(null);
  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  

  return (
    <div>
      {isLoading ? (
        <div className="flex w-full max-h-lvh/0.5 items-center justify-center">
          <Spinner />
        </div>
      ) : isSuccess ? (
        data.length > 0 ? (
          <>
            <div className="flex w-full justify-between items-center">
              <div className="flex gap-x-2 mb-4">
                <div className=" w-[24px] h-[24px] rounded-full bg-primary-500 flex items-center justify-center text-white text-sm">
                  1
                </div>
                <div className="font-semibold text-neutral-900 text-lg">
                  Select a template
                </div>
              </div>
              <Link
                to={"/offer-generation/template-offer-form"}
                className=" rounded bg-primary-500 text-white text-sm py-2 px-4"
              >
                Add a template
              </Link>
            </div>
            <div className=" flex flex-col gap-y-6">
              {data.map((template: any) => (
                <TemplateCard
                  refetch={refetch}
                  key={template.id}
                  isSelected={isSelected}
                  setIsSelected={(template) => {
                    setIsSelected(template);
                    setIsGenerated(false);
                  }}
                  template={template}
                />
              ))}
            </div>
            {isSelected && (
              <div className="flex w-full justify-end mt-6 xs:mt-10">
                <button
                  disabled={isGenerated}
                  onClick={() => setIsGenerated(true)}
                  className={`${
                    isGenerated ? " bg-primary-500/30" : "bg-primary-500"
                  }  items-center flex gap-x-1 text-sm text-white rounded py-2 px-5`}
                >
                  <LuWand size={17} />
                  Manage offer
                </button>
              </div>
            )}
            {isGenerated && (
              <div className="fixed top-0 left-0 h-full w-full bg-black/40 px-3 flex items-center justify-center">
                <OfferForm
                  merchant_id={Number(merchantId)}
                  onConfirm={() => console.log()}
                  selected_template={isSelected}
                  onCancel={() => setIsGenerated(false)}
                />
              </div>
            )}
            {openConfirm && (
              <ConfirmPopup
                confirmButtonTitle="Send offer"
                onCancel={() => setOpenConfirm(false)}
                onConfirm={() => console.log()}
                title="Are you absolutely sure?"
                description="This action cannot be undone. This will permanently send the merchant offer and allow him to move to sign it."
              />
            )}
          </>
        ) : (
          <>
            <Link
              to={"/offer-generation/template-offer-form"}
              className=" rounded bg-primary-500 text-white text-sm py-2 px-4"
            >
              Add a template
            </Link>
          </>
        )
      ) : null}
    </div>
  );
};
