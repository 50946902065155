import * as yup from "yup";
export const generateOfferSchema = yup.object().shape({
  approval_fee: yup.number().required("Required"),
  failure_fee: yup.number().required("Required"),
  refund_fee: yup.number().required("Required"),
  chargeback_fee: yup.number().required("Required"),
  daily_limit: yup.number().required("Required"),
  type: yup.string().required("Required"),
  global_min_transaction: yup.number().required("Required"),
  global_max_transaction: yup.number().required("Required"),
  kyc_requirement: yup.boolean().required("Required"),

  // mdr: yup.number().required("Required"),
  // rolling_reserve_amount: yup.number().required("Required"),
  // rolling_reserve_percent: yup.number().required("Required"),
  // rolling_reserve_duration: yup.number().required("Required"),
  // transaction_limit: yup.number().required("Required"),
  // settlement_fee: yup.number().required("Required"),
  // settlement_cycle: yup.string().required("Required"),
  // average_transaction_size: yup.number().required("Required"),
  // average_monthly_volume: yup.number().required("Required"),
  // minimum_transaction_size: yup.number().required("Required"),
  // maximum_transaction_size: yup.number().required("Required"),
  // countries_of_operations: yup.array().required("Required"),
  // currencies: yup.array(),
  // trusted_traffic: yup.array().required("Required"),
  // non_trusted_traffic: yup.array().required("Required"),
  // global_wires_mdr: yup.number().required("Required"),
  // settlement_time: yup.string().required("Required"),
  // callback_refund_fees: yup.number().required("Required"),
  // usdt_settlement_fees: yup.number().required("Required"),
  // rolling_reserve_percent_global: yup.number(),
  // rolling_reserve_duration_global: yup.number().required("Required"),
  // payment_methods_accepted: yup.array().required("Required"),
  // pay_in_and_out: yup.string().required("Required"),
  // exchange_rate: yup.string().required("Required"),
  // limit_per_day_transactions: yup.number().required("Required"),
  // limit_per_day_customer: yup.number(),
  // limit_per_day_mid: yup.number(),
  // kyc_documents: yup.array().required("Required"),
  // pop: yup.string().required("Required"),
});
