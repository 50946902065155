import { useEffect, useState, Suspense } from "react";
import { useMqr } from "../../../../services/api";
import { Spinner } from "../../../commun";
import { MqrTable } from "../../components/mqr-table";
import getDate from "../../../../core/utils/get-date";
import { FilterSection } from "../../../marchants/components/filter-section";
export const MqrReviewTemplate = () => {
  const [search, setSearch] = useState("");
  const [filteredData, setFiteredData] = useState<Array<any>>([]);
  const [date, setDate] = useState<Date | undefined>();
  const { data: mqrs, isPending, isSuccess } = useMqr();

  const filterData = (value: any) => {
    setSearch(value);
    let newFiltered = mqrs.filter((item: any) =>
      item.email.toLowerCase().includes(value.toLowerCase())
    );
    setFiteredData([...newFiltered]);
  };

  useEffect(() => {
    mqrs && mqrs.length > 0 && setFiteredData([...mqrs]);
  }, [mqrs, isSuccess, isPending]);

  useEffect(() => {
    if (date) {
      let newDate = getDate(date);
      let newFiltered =
        mqrs &&
        mqrs.length > 0 &&
        mqrs?.filter((item: any) => getDate(item.created_at) === newDate);
      setFiteredData([...newFiltered]);
    } else {
      mqrs && mqrs.length > 0 && setFiteredData([...mqrs]);
    }
  }, [date]);

  return (
    <div className=" ">
      {isPending ? (
        <div className=" flex-1 h-[90vh] flex justify-center items-center">
          <Spinner />
        </div>
      ) : mqrs && isSuccess ? (
        <div className=" flex flex-col gap-y-4">
          <FilterSection
            date={date}
            setDate={setDate}
            search={search}
            filterData={(e) => filterData(e)}
          />
          <div className="md:w-auto w-full overflow-x-auto x-global-bg py-2 md:py-0">
            <MqrTable items={filteredData} />
          </div>
        </div>
      ) : (
        <div className=" flex-1 h-[90vh] flex justify-center items-center">
          <Spinner />
        </div>
      )}
    </div>
  );
};
