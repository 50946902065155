import { Link, useParams } from "react-router-dom";

import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { MerchantDetailsTemplate } from "../../modules/marchants/templates/merchant-details";
import { IoIosArrowBack } from "react-icons/io";
import { useUserDetails } from "../../services/api";
import { Spinner } from "../../modules/commun";

export const MerchantDetails = () => {
  let { merchantId } = useParams();
  const { data, isLoading } = useUserDetails(Number(merchantId));
  return (
    <div className=" py-6 ">
      <div className="flex items-center gap-x-3 mb-6">
        <Link
          to={"/"}
          className=" bg-neutral-100 h-[30px] text-neutral-500 flex items-center justify-center rounded aspect-square"
        >
          <IoIosArrowBack size={18} />
        </Link>
        <div className=" font-bold text-2xl xs:text-4xl">Merchant Details</div>
      </div>
      {isLoading ? <Spinner /> : <MerchantDetailsTemplate merchant={data} />}
    </div>
  );
};
