export const MqrAttribute = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <div className=" pb-2 border-b border-neutral-300">
      <div className="font-semibold text-sm text-neutral-900">{title}</div>
      <div className="font-semibold text-xs text-neutral-500">{value}</div>
    </div>
  );
};
