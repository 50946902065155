import { LiaEdit } from "react-icons/lia";
import { PiTrash } from "react-icons/pi";
import getDate from "../../../../core/utils/get-date";
import { useDeletePredefinedOffer } from "../../../../services/api";
import { ConfirmPopup } from "../../../commun/confirm-popup";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const TemplateCard = ({
  template,
  isSelected,
  setIsSelected,
  refetch,
}: {
  template: any;
  isSelected?: any;
  setIsSelected: (template: any) => void;
  refetch: () => void;
}) => {
  const navigate = useNavigate();
  const [showPopUp, setShowPopUP] = useState<boolean>(false);
  const { mutateAsync, isPending } = useDeletePredefinedOffer();
  const deleteTemplate = async () => {
    await mutateAsync(template.id, {
      onSuccess: (response) => {
        // console.log("response ", response);
        setShowPopUP(false);
        refetch();
      },
      onError: (error) => {
        console.log("from ", error);
      },
    }).catch((error) => console.log());
  };
  return (
    <button
      onClick={() => setIsSelected(template)}
      className={`${
        isSelected?.id === template.id
          ? " border-2 border-green-600"
          : "shadow-lg shadow-black/20"
      } bg-white rounded justify-between items-center flex py-2 px-4`}
    >
      <div className="flex gap-x-4">
        <img src="/FileType.png" className="w-[56px] h-[56px]" alt="template" />
        <div className=" gap-y-2 flex flex-col justify-between items-start">
          <div className="font-bold text-neutral-900 text-lg">
            {template.name}
          </div>
          <div className=" text-base text-neutral-500">
            Added on {getDate(template.created_at)}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-x-7">
        <button
          onClick={() =>
            navigate("/offer-generation/template-offer-form", {
              state: { template: template },
            })
          }
          className=" text-primary-500 gap-x-1 flex items-center"
        >
          <LiaEdit size={17} />
          <div className=" text-sm">Edit</div>
        </button>
        <button
          onClick={() => setShowPopUP(true)}
          className=" text-red-600 gap-x-1 flex items-center"
        >
          <PiTrash size={17} />
          <div className=" text-sm">Delete</div>
        </button>
      </div>
      {showPopUp && (
        <ConfirmPopup
          title="Are you sure you want to delete this template?"
          onCancel={() => setShowPopUP(false)}
          confirmButtonTitle="Yes"
          onConfirm={deleteTemplate}
        />
      )}
    </button>
  );
};
