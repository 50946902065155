import { MqrReviewTemplate } from "../../modules/mqr/templates/mqrs-review";

export const MQRs = () => {
  return (
    <div className="md:py-10 py-3">
      <div className=" font-bold text-2xl xs:text-4xl mb-6">MQR Review</div>
      <MqrReviewTemplate />
    </div>
  );
};
