import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useQuery } from "@tanstack/react-query";

const getMqrDetails = async (id: number) => {
  const { data } = await client.get(`admin/mqr/${id}`, {
    timeout: 2000, // since it can be heavy too
    headers: {
      Authorization: `Bearer ${getItem("auth")}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return data.data;
};

export function useMqrDetails(id: number) {
  return useQuery({
    queryKey: ["get-mqr-details"],
    queryFn: () => getMqrDetails(id),
  });
}
