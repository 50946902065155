import { create } from "zustand";
import { User } from "../types";
interface AppStore {
  user: User | null;
  setUser: (user: User) => void;
}

export const useAppStore = create<AppStore>((set, get) => ({
  user: null,

  setUser: (data) => {
    set({
      user: data,
    });
  },
}));

export const setUserFct = (user: User) => useAppStore.getState().setUser(user);
