import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useQuery } from "@tanstack/react-query";

const getMqr = async () => {
  const { data } = await client.get(`admin/mqr`, {
    timeout: 2000, // since it can be heavy too
    headers: {
      Authorization: `Bearer ${getItem("auth")}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return data.data;
};

export function useMqr() {
  return useQuery({ queryKey: ["get-mqrs"], queryFn: getMqr });
}
