import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RoutesLayout from "./Routes";
import {
  Login,
  Home,
  MerchantDetails,
  MqrDetails,
  MQRs,
  OfferGeneration,
  OfferTemplates,
  OfferSent,
  KybReviews,
  DocumentsList,
  TemplateForm,
  OfferDetails,
} from "../pages";
import APIProvider from "../services/global/APIProvider";
import { useAuth } from "../core/auth";
const Router = () => {
  const { status } = useAuth();
  const router = createBrowserRouter([
    {
      path: "/",
      element:
        status === "signIn" ? <RoutesLayout /> : <Navigate to={"/login"} />,
      // status === "signIn" ? <RoutesLayout /> : <Navigate to={"/login"} />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/merchant-details/:merchantId", element: <MerchantDetails /> },
        {
          path: "/mqr-review/mqr-details/:merchantId",
          element: <MqrDetails />,
        },
        { path: "/mqr-review", element: <MQRs /> },
        { path: "/offer-generation", element: <OfferGeneration /> },
        {
          path: "/offer-generation/offer-templates/:merchantId",
          element: <OfferTemplates />,
        },
        {
          path: "/offer-generation/template-offer-form",
          element: <TemplateForm />,
        },
        {
          path: "/offer-generation/offer-templates/:merchantId/offer-sent",
          element: <OfferSent />,
        },
        {
          path: "/offer-generation/:merchantId/:merchantName",
          element: <OfferDetails />,
        },
        { path: "/kyb-reviews", element: <KybReviews /> },
        { path: "/kyb-reviews/documents", element: <DocumentsList /> },
        //   { path: "/account-setup/company-profile", element: <CompanyProfile /> },
      ],
    },
    {
      path: "/login",
      element: status !== "signIn" ? <Login /> : <Navigate to={"/"} />,
      //   element: status !== "signIn" ? <Login /> : <Navigate to={"/"} />,
    },
  ]);

  return (
    <APIProvider>
      <RouterProvider router={router} />
    </APIProvider>
  );
};
export default Router;
