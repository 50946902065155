import { useMutation } from "@tanstack/react-query";
import { client } from "../../global/apiClient";
import { useAuth } from "../../../core/auth";
import { setUserFct } from "../../../core/appStore";

export function useSignIn() {
  const { signIn, signOut } = useAuth();
  return useMutation(
    // (data: any) => client.post("login", data, { timeout: 5000 }),
    {
      mutationFn: (data: any) => client.post("login", data, { timeout: 5000 }),
      onError: (error: any) => {
        // console.log(error);
      },
      onSuccess(response: any) {
        if (response.data.data.roles[0].name === "admin") {
          signIn(response.data.token);
          setUserFct(response.data.data);
        } else {
          signOut();
        }
      },
    }
  );
}
