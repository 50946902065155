export const countryList = [
  {
    id: 1,
    value: "AF",
    label: "AF",
  },
  {
    id: 2,
    value: "ZA",
    label: "ZA",
  },
  {
    id: 3,
    value: "AL",
    label: "AL",
  },
  {
    id: 4,
    value: "DE",
    label: "DE",
  },
  {
    id: 5,
    value: "AD",
    label: "AD",
  },
  {
    id: 6,
    value: "AO",
    label: "AO",
  },
  {
    id: 7,
    value: "AI",
    label: "AI",
  },
  {
    id: 8,
    value: "AQ",
    label: "AQ",
  },
  {
    id: 9,
    value: "AG",
    label: "AG",
  },
  {
    id: 11,
    value: "SA",
    label: "SA",
  },
  {
    id: 12,
    value: "DZ",
    label: "DZ",
  },
  {
    id: 13,
    value: "AR",
    label: "AR",
  },
  {
    id: 14,
    value: "AM",
    label: "AM",
  },
  {
    id: 15,
    value: "AW",
    label: "AW",
  },
  {
    id: 16,
    value: "AU",
    label: "AU",
  },
  {
    id: 17,
    value: "AT",
    label: "AT",
  },
  {
    id: 18,
    value: "AZ",
    label: "AZ",
  },
  {
    id: 19,
    value: "BS",
    label: "BS",
  },
  {
    id: 20,
    value: "BH",
    label: "BH",
  },
  {
    id: 21,
    value: "BD",
    label: "BD",
  },
  {
    id: 22,
    value: "BB",
    label: "BB",
  },
  {
    id: 23,
    value: "BE",
    label: "BE",
  },
  {
    id: 24,
    value: "BZ",
    label: "BZ",
  },
  {
    id: 25,
    value: "BJ",
    label: "BJ",
  },
  {
    id: 26,
    value: "BM",
    label: "BM",
  },
  {
    id: 27,
    value: "BY",
    label: "BY",
  },
  {
    id: 28,
    value: "BO",
    label: "BO",
  },
  {
    id: 29,
    value: "BA",
    label: "BA",
  },
  {
    id: 30,
    value: "BW",
    label: "BW",
  },
  {
    id: 31,
    value: "BR",
    label: "BR",
  },
  {
    id: 32,
    value: "BN",
    label: "BN",
  },
  {
    id: 33,
    value: "BG",
    label: "BG",
  },
  {
    id: 34,
    value: "BF",
    label: "BF",
  },
  {
    id: 35,
    value: "BI",
    label: "BI",
  },
  {
    id: 36,
    value: "BT",
    label: "BT",
  },
  {
    id: 37,
    value: "CV",
    label: "CV",
  },
  {
    id: 38,
    value: "CM",
    label: "CM",
  },
  {
    id: 39,
    value: "KH",
    label: "KH",
  },
  {
    id: 40,
    value: "CA",
    label: "CA",
  },
  {
    id: 41,
    value: "QA",
    label: "QA",
  },
  {
    id: 42,
    value: "KZ",
    label: "KZ",
  },
  {
    id: 43,
    value: "TD",
    label: "TD",
  },
  {
    id: 44,
    value: "CL",
    label: "CL",
  },
  {
    id: 45,
    value: "CN",
    label: "CN",
  },
  {
    id: 46,
    value: "CY",
    label: "CY",
  },
  {
    id: 47,
    value: "SG",
    label: "SG",
  },
  {
    id: 48,
    value: "CO",
    label: "CO",
  },
  {
    id: 49,
    value: "KM",
    label: "KM",
  },
  {
    id: 50,
    value: "CG",
    label: "CG",
  },
  {
    id: 51,
    value: "KP",
    label: "KP",
  },
  {
    id: 52,
    value: "KR",
    label: "KR",
  },
  {
    id: 53,
    value: "CI",
    label: "CI",
  },
  {
    id: 54,
    value: "CR",
    label: "CR",
  },
  {
    id: 55,
    value: "HR",
    label: "HR",
  },
  {
    id: 56,
    value: "CU",
    label: "CU",
  },
  {
    id: 57,
    value: "CW",
    label: "CW",
  },
  {
    id: 58,
    value: "DK",
    label: "DK",
  },
  {
    id: 59,
    value: "DJ",
    label: "DJ",
  },
  {
    id: 60,
    value: "DM",
    label: "DM",
  },
  {
    id: 61,
    value: "EG",
    label: "EG",
  },
  {
    id: 62,
    value: "SV",
    label: "SV",
  },
  {
    id: 63,
    value: "AE",
    label: "AE",
  },
  {
    id: 64,
    value: "EC",
    label: "EC",
  },
  {
    id: 65,
    value: "ER",
    label: "ER",
  },
  {
    id: 66,
    value: "SK",
    label: "SK",
  },
  {
    id: 67,
    value: "SI",
    label: "SI",
  },
  {
    id: 68,
    value: "ES",
    label: "ES",
  },
  {
    id: 69,
    value: "US",
    label: "US",
  },
  {
    id: 70,
    value: "EE",
    label: "EE",
  },
  {
    id: 71,
    value: "ET",
    label: "ET",
  },
  {
    id: 72,
    value: "FJ",
    label: "FJ",
  },
  {
    id: 73,
    value: "PH",
    label: "PH",
  },
  {
    id: 74,
    value: "FI",
    label: "FI",
  },
  {
    id: 75,
    value: "FR",
    label: "FR",
  },
  {
    id: 76,
    value: "GA",
    label: "GA",
  },
  {
    id: 77,
    value: "GM",
    label: "GM",
  },
  {
    id: 78,
    value: "GH",
    label: "GH",
  },
  {
    id: 79,
    value: "GE",
    label: "GE",
  },
  {
    id: 80,
    value: "GI",
    label: "GI",
  },
  {
    id: 81,
    value: "GD",
    label: "GD",
  },
  {
    id: 82,
    value: "GR",
    label: "GR",
  },
  {
    id: 83,
    value: "GL",
    label: "GL",
  },
  {
    id: 84,
    value: "GP",
    label: "GP",
  },
  {
    id: 85,
    value: "GU",
    label: "GU",
  },
  {
    id: 86,
    value: "GT",
    label: "GT",
  },
  {
    id: 87,
    value: "GG",
    label: "GG",
  },
  {
    id: 88,
    value: "GY",
    label: "GY",
  },
  {
    id: 89,
    value: "GF",
    label: "GF",
  },
  {
    id: 90,
    value: "GN",
    label: "GN",
  },
  {
    id: 91,
    value: "GQ",
    label: "GQ",
  },
  {
    id: 92,
    value: "GW",
    label: "GW",
  },
  {
    id: 93,
    value: "HT",
    label: "HT",
  },
  {
    id: 94,
    value: "NL",
    label: "NL",
  },
  {
    id: 95,
    value: "HN",
    label: "HN",
  },
  {
    id: 96,
    value: "HK",
    label: "HK",
  },
  {
    id: 97,
    value: "HU",
    label: "HU",
  },
  {
    id: 98,
    value: "YE",
    label: "YE",
  },
  {
    id: 99,
    value: "BV",
    label: "BV",
  },
  {
    id: 100,
    value: "RE",
    label: "RE",
  },
  {
    id: 101,
    value: "IM",
    label: "IM",
  },
  {
    id: 102,
    value: "MF",
    label: "MF",
  },
  {
    id: 103,
    value: "HM",
    label: "HM",
  },
  {
    id: 104,
    value: "CX",
    label: "CX",
  },
  {
    id: 105,
    value: "NF",
    label: "NF",
  },
  {
    id: 106,
    value: "AX",
    label: "AX",
  },
  {
    id: 107,
    value: "KY",
    label: "KY",
  },
  {
    id: 108,
    value: "CC",
    label: "CC",
  },
  {
    id: 109,
    value: "CK",
    label: "CK",
  },
  {
    id: 110,
    value: "FK",
    label: "FK",
  },
  {
    id: 111,
    value: "FO",
    label: "FO",
  },
  {
    id: 112,
    value: "GS",
    label: "GS",
  },
  {
    id: 113,
    value: "MP",
    label: "MP",
  },
  {
    id: 114,
    value: "MH",
    label: "MH",
  },
  {
    id: 115,
    value: "UM",
    label: "UM",
  },
  {
    id: 116,
    value: "PN",
    label: "PN",
  },
  {
    id: 117,
    value: "SB",
    label: "SB",
  },
  {
    id: 118,
    value: "TC",
    label: "TC",
  },
  {
    id: 119,
    value: "VI",
    label: "VI",
  },
  {
    id: 120,
    value: "VG",
    label: "VG",
  },
  {
    id: 121,
    value: "IN",
    label: "IN",
  },
  {
    id: 122,
    value: "ID",
    label: "ID",
  },
  {
    id: 123,
    value: "IR",
    label: "IR",
  },
  {
    id: 124,
    value: "IQ",
    label: "IQ",
  },
  {
    id: 125,
    value: "IE",
    label: "IE",
  },
  {
    id: 126,
    value: "IS",
    label: "IS",
  },
  {
    id: 127,
    value: "IL",
    label: "IL",
  },
  {
    id: 128,
    value: "IT",
    label: "IT",
  },
  {
    id: 129,
    value: "JM",
    label: "JM",
  },
  {
    id: 130,
    value: "JP",
    label: "JP",
  },
  {
    id: 131,
    value: "JE",
    label: "JE",
  },
  {
    id: 132,
    value: "JO",
    label: "JO",
  },
  {
    id: 133,
    value: "KI",
    label: "KI",
  },
  {
    id: 134,
    value: "XK",
    label: "XK",
  },
  {
    id: 135,
    value: "KW",
    label: "KW",
  },
  {
    id: 136,
    value: "LA",
    label: "LA",
  },
  {
    id: 137,
    value: "LS",
    label: "LS",
  },
  {
    id: 138,
    value: "LV",
    label: "LV",
  },
  {
    id: 139,
    value: "LB",
    label: "LB",
  },
  {
    id: 140,
    value: "LR",
    label: "LR",
  },
  {
    id: 141,
    value: "LY",
    label: "LY",
  },
  {
    id: 142,
    value: "LI",
    label: "LI",
  },
  {
    id: 143,
    value: "LT",
    label: "LT",
  },
  {
    id: 144,
    value: "LU",
    label: "LU",
  },
  {
    id: 145,
    value: "MO",
    label: "MO",
  },
  {
    id: 146,
    value: "MK",
    label: "MK",
  },
  {
    id: 147,
    value: "MG",
    label: "MG",
  },
  {
    id: 148,
    value: "MY",
    label: "MY",
  },
  {
    id: 149,
    value: "MW",
    label: "MW",
  },
  {
    id: 150,
    value: "MV",
    label: "MV",
  },
  {
    id: 151,
    value: "ML",
    label: "ML",
  },
  {
    id: 152,
    value: "MT",
    label: "MT",
  },
  {
    id: 153,
    value: "MA",
    label: "MA",
  },
  {
    id: 154,
    value: "MQ",
    label: "MQ",
  },
  {
    id: 155,
    value: "MU",
    label: "MU",
  },
  {
    id: 156,
    value: "MR",
    label: "MR",
  },
  {
    id: 157,
    value: "YT",
    label: "YT",
  },
  {
    id: 158,
    value: "MX",
    label: "MX",
  },
  {
    id: 159,
    value: "FM",
    label: "FM",
  },
  {
    id: 160,
    value: "MZ",
    label: "MZ",
  },
  {
    id: 161,
    value: "MD",
    label: "MD",
  },
  {
    id: 162,
    value: "MC",
    label: "MC",
  },
  {
    id: 163,
    value: "MN",
    label: "MN",
  },
  {
    id: 164,
    value: "ME",
    label: "ME",
  },
  {
    id: 165,
    value: "MS",
    label: "MS",
  },
  {
    id: 166,
    value: "MM",
    label: "MM",
  },
  {
    id: 167,
    value: "NA",
    label: "NA",
  },
  {
    id: 168,
    value: "NR",
    label: "NR",
  },
  {
    id: 169,
    value: "NP",
    label: "NP",
  },
  {
    id: 170,
    value: "NI",
    label: "NI",
  },
  {
    id: 171,
    value: "NE",
    label: "NE",
  },
  {
    id: 172,
    value: "NG",
    label: "NG",
  },
  {
    id: 173,
    value: "NU",
    label: "NU",
  },
  {
    id: 174,
    value: "NO",
    label: "NO",
  },
  {
    id: 175,
    value: "NC",
    label: "NC",
  },
  {
    id: 176,
    value: "NZ",
    label: "NZ",
  },
  {
    id: 177,
    value: "OM",
    label: "OM",
  },
  {
    id: 178,
    value: "PW",
    label: "PW",
  },
  {
    id: 179,
    value: "PA",
    label: "PA",
  },
  {
    id: 180,
    value: "PG",
    label: "PG",
  },
  {
    id: 181,
    value: "PK",
    label: "PK",
  },
  {
    id: 182,
    value: "PY",
    label: "PY",
  },
  {
    id: 183,
    value: "PE",
    label: "PE",
  },
  {
    id: 184,
    value: "PF",
    label: "PF",
  },
  {
    id: 185,
    value: "PL",
    label: "PL",
  },
  {
    id: 186,
    value: "PR",
    label: "PR",
  },
  {
    id: 187,
    value: "PT",
    label: "PT",
  },
  {
    id: 188,
    value: "KE",
    label: "KE",
  },
  {
    id: 189,
    value: "KG",
    label: "KG",
  },
  {
    id: 190,
    value: "GB",
    label: "GB",
  },
  {
    id: 191,
    value: "CF",
    label: "CF",
  },
  {
    id: 192,
    value: "CD",
    label: "CD",
  },
  {
    id: 193,
    value: "DO",
    label: "DO",
  },
  {
    id: 194,
    value: "CZ",
    label: "CZ",
  },
  {
    id: 195,
    value: "RO",
    label: "RO",
  },
  {
    id: 196,
    value: "RW",
    label: "RW",
  },
  {
    id: 197,
    value: "RU",
    label: "RU",
  },
  {
    id: 198,
    value: "EH",
    label: "EH",
  },
  {
    id: 199,
    value: "BQ",
    label: "BQ",
  },
  {
    id: 200,
    value: "WS",
    label: "WS",
  },
  {
    id: 201,
    value: "AS",
    label: "AS",
  },
  {
    id: 202,
    value: "SM",
    label: "SM",
  },
  {
    id: 203,
    value: "SH",
    label: "SH",
  },
  {
    id: 204,
    value: "LC",
    label: "LC",
  },
  {
    id: 205,
    value: "BL",
    label: "BL",
  },
  {
    id: 206,
    value: "KN",
    label: "KN",
  },
  {
    id: 207,
    value: "SX",
    label: "SX",
  },
  {
    id: 208,
    value: "PM",
    label: "PM",
  },
  {
    id: 209,
    value: "ST",
    label: "ST",
  },
  {
    id: 210,
    value: "VC",
    label: "VC",
  },
  {
    id: 211,
    value: "SN",
    label: "SN",
  },
  {
    id: 212,
    value: "SL",
    label: "SL",
  },
  {
    id: 213,
    value: "RS",
    label: "RS",
  },
  {
    id: 215,
    value: "SC",
    label: "SC",
  },
  {
    id: 216,
    value: "SY",
    label: "SY",
  },
  {
    id: 217,
    value: "SO",
    label: "SO",
  },
  {
    id: 218,
    value: "LK",
    label: "LK",
  },
  {
    id: 219,
    value: "SZ",
    label: "SZ",
  },
  {
    id: 220,
    value: "SD",
    label: "SD",
  },
  {
    id: 221,
    value: "SS",
    label: "SS",
  },
  {
    id: 222,
    value: "SE",
    label: "SE",
  },
  {
    id: 223,
    value: "CH",
    label: "CH",
  },
  {
    id: 224,
    value: "SR",
    label: "SR",
  },
  {
    id: 225,
    value: "SJ",
    label: "SJ",
  },
  {
    id: 226,
    value: "TH",
    label: "TH",
  },
  {
    id: 227,
    value: "TW",
    label: "TW",
  },
  {
    id: 228,
    value: "TJ",
    label: "TJ",
  },
  {
    id: 229,
    value: "TZ",
    label: "TZ",
  },
  {
    id: 230,
    value: "TF",
    label: "TF",
  },
  {
    id: 231,
    value: "IO",
    label: "IO",
  },
  {
    id: 232,
    value: "PS",
    label: "PS",
  },
  {
    id: 233,
    value: "TL",
    label: "TL",
  },
  {
    id: 234,
    value: "TG",
    label: "TG",
  },
  {
    id: 235,
    value: "TO",
    label: "TO",
  },
  {
    id: 236,
    value: "TK",
    label: "TK",
  },
  {
    id: 237,
    value: "TT",
    label: "TT",
  },
  {
    id: 238,
    value: "TN",
    label: "TN",
  },
  {
    id: 239,
    value: "TM",
    label: "TM",
  },
  {
    id: 240,
    value: "TR",
    label: "TR",
  },
  {
    id: 241,
    value: "TV",
    label: "TV",
  },
  {
    id: 242,
    value: "UA",
    label: "UA",
  },
  {
    id: 243,
    value: "UG",
    label: "UG",
  },
  {
    id: 244,
    value: "UY",
    label: "UY",
  },
  {
    id: 245,
    value: "UZ",
    label: "UZ",
  },
  {
    id: 246,
    value: "VU",
    label: "VU",
  },
  {
    id: 247,
    value: "VA",
    label: "VA",
  },
  {
    id: 248,
    value: "VE",
    label: "VE",
  },
  {
    id: 249,
    value: "VN",
    label: "VN",
  },
  {
    id: 250,
    value: "WF",
    label: "WF",
  },
  {
    id: 251,
    value: "ZM",
    label: "ZM",
  },
  {
    id: 252,
    value: "ZW",
    label: "ZW",
  },
];
