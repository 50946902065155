import { Outlet } from "react-router-dom";
import NavBar from "../modules/navbar";
import { useLoggedUser } from "../services/api";
import { Spinner } from "../modules/commun";

const RoutesLayout = () => {
  const { data, isLoading } = useLoggedUser();

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className="flex flex-col md:flex-row">
            <NavBar />
            <div className=" relative  flex-1 py-2 px-3 md:px-10 lg:px-12 h-[100vh] overflow-y-auto y-global-bg ">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default RoutesLayout;
