import getDate from "../../../../core/utils/get-date";
import { MqrAttribute } from "../mqr-attribute";
import { FiPrinter } from "react-icons/fi";

export const MqrDetailsContainer = ({ mqr }: any) => {
  return (
    <div className="flex w-full justify-between items-start gap-x-4 %] sm:flex-row flex-col-reverse gap-y-3">
      <div className="p-4 flex flex-col gap-y-2 w-full md:w-[60%] min-w-[240px] border border-neutral-300 rounded-lg ">
        <div className="flex justify-between mb-2">
          <div className="font-bold">Company Profile</div>
          <button className=" outline-none  w-10 h-10 rounded flex items-center justify-center border border-neutral-300 text-neutral-900">
            <FiPrinter size={16} />
          </button>
        </div>
        <MqrAttribute title="Company name" value={mqr.companyName} />
        <MqrAttribute
          title="Registration Number"
          value={mqr.registrationNumber}
        />
        <MqrAttribute title="Registred Address" value={mqr.registeredAddress} />
        <MqrAttribute title="Country of Incorporation" value={mqr.country} />
        <MqrAttribute title="Company size" value={mqr.CompanySize} />
        <MqrAttribute title="Email" value={mqr.email} />
        <MqrAttribute title="Company website" value={mqr.website} />
        <MqrAttribute title="Company website" value={mqr.website} />
        <MqrAttribute title="Citizenship" value={mqr.citizenship} />
        <MqrAttribute title="Passeport Number" value={mqr.passportNumber} />
        <MqrAttribute title="Position" value={mqr.position} />

        {mqr.cc && (
          <>
            <div className="flex justify-between my-1">
              <div className="font-bold">Card</div>
              <div></div>
            </div>
            <MqrAttribute
              title="Average Transaction Size"
              value={`$ ${mqr.cc.avg_transaction_size}`}
            />
            <MqrAttribute
              title="Average monthly transactions volume"
              value={`$ ${mqr.cc.monthlyVolume}`}
            />
            <MqrAttribute
              title="Min Transaction Size - Max Transaction Size"
              value={`$ ${mqr.cc.min_transaction} - $ ${mqr.cc.max_transaction} `}
            />
            <MqrAttribute title="Currencies" value={mqr.cc.currencies + " "} />
            <MqrAttribute title="Countries" value={mqr.cc.countries + " "} />
          </>
        )}
        {mqr.wire && (
          <>
            <div className="flex justify-between my-1">
              <div className="font-bold">Wire</div>
              <div></div>
            </div>
            <MqrAttribute
              title="Average Transaction Size"
              value={`$ ${mqr.wire.avg_transaction_size}`}
            />
            <MqrAttribute
              title="Average monthly transactions volume"
              value={`$ ${mqr.wire.monthlyVolume}`}
            />
            <MqrAttribute
              title="Min Transaction Size - Max Transaction Size"
              value={`$ ${mqr.wire.min_transaction} - $ ${mqr.wire.max_transaction} `}
            />
            <MqrAttribute
              title="Currencies"
              value={mqr.wire.currencies + " "}
            />
            <MqrAttribute title="Countries" value={mqr.wire.countries + " "} />
          </>
        )}
      </div>

      <div className=" bg-neutral-100 p-4 rounded-lg flex flex-col gap-y-3 w-full sm:max-w-[320px]">
        <div className=" flex flex-col gap-y-1">
          <div className=" text-neutral-600 text-sm">Company name</div>
          <div className=" text-neutral-900 text-sm font-semibold">
            {mqr.companyName}
          </div>
        </div>

        <div className=" flex flex-col gap-y-1 items-start">
          <div className=" text-neutral-600 text-sm">MQR status</div>
          <div className=" py-[2px] px-[10px] font-[600] rounded-full text-white text-sm  bg-secondary-600">
            Submitted
          </div>
        </div>

        <div className=" flex flex-col gap-y-1">
          <div className=" text-neutral-600 text-sm">Submission date</div>
          <div className=" text-neutral-900 text-sm font-semibold">
            {getDate(mqr.created_at)}
          </div>
        </div>

        <div className=" flex flex-col gap-y-1">
          <div className=" text-neutral-600 text-sm">Last update on</div>
          <div className=" text-neutral-900 text-sm font-semibold">
            {getDate(mqr.updated_at)}
          </div>
        </div>
      </div>
    </div>
  );
};
