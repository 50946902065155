import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useQuery } from "@tanstack/react-query";

const getUserDetails = async (id: number) => {
  const { data } = await client.get(`admin/users/${id}`, {
    timeout: 2000, // since it can be heavy too
    headers: {
      Authorization: `Bearer ${getItem("auth")}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return data;
};

export function useUserDetails(id: number) {
  return useQuery({
    queryKey: ["get-user-details"],
    queryFn: () => getUserDetails(id),
  });
}
