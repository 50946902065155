import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useQuery } from "@tanstack/react-query";

const getPredefinedOffers = async () => {
  const { data } = await client.get(`admin/offers/predefined/all`, {
    timeout: 2000, // since it can be heavy too
    headers: {
      Authorization: `Bearer ${getItem("auth")}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return data.data;
};

export function usePredefinedOffers() {
  return useQuery({
    queryKey: ["get-predefined-offers"],
    queryFn: getPredefinedOffers,
  });
}
