import { ReactNode } from "react";

interface confirmType {
  title: string;
  description?: string;
  confirmButtonTitle: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
}

export const ConfirmPopup = ({
  title,
  confirmButtonTitle,
  description,
  onConfirm,
  onCancel,
  children,
}: confirmType) => {
  return (
    <div className=" fixed top-0 left-0 h-full w-full bg-black/30 px-3 flex items-center justify-center">
      <div className="flex flex-col gap-y-1 bg-white rounded-lg shadow-lg shadow-black/30 p-4 w-full max-w-[420px]">
        <div className="font-bold text-lg ">{title}</div>
        <div className=" text-sm text-neutral-600">{description}</div>

        <div className="flex items-center justify-end gap-x-2">
          {onCancel && (
            <button
              onClick={onCancel}
              className=" text-neutral-600 rounded bg-white border border-neutral-300 py-2 px-4 outline-none text-sm"
            >
              Cancel
            </button>
          )}

          <button
            onClick={onConfirm}
            className=" text-white rounded bg-primary-500 border-none py-2 px-4 outline-none text-sm"
          >
            {confirmButtonTitle}
          </button>
        </div>
      </div>
    </div>
  );
};
