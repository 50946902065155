import { GoEye } from "react-icons/go";
import getDate from "../../../../core/utils/get-date";

export const DocumentCard = ({
  document,
  setShowModal,
  setSelectedFile,
}: {
  document: any;
  setShowModal: any;
  setSelectedFile: (document: any) => void;
}) => {
  return (
    <div
      className={` bg-white border border-neutral-300 rounded items-center justify-between w-full flex py-2 px-4`}
    >
      <div className="flex gap-x-4 items-center">
        <img src="/pdfType.png" className="w-[40px] h-[40px]" alt="template" />
        <div className=" gap-y-2 flex flex-col justify-between items-start">
          <div className="font-bold text-neutral-900 text-lg">
            {document.file.type}
          </div>
          <div className=" text-base text-neutral-500">
            {getDate(document.file.created_at)}
          </div>
        </div>
      </div>
      {true ? (
        <button
          onClick={() => {
            setShowModal();
            setSelectedFile(document);
          }}
          className=" border border-neutral-300 rounded flex items-center gap-x-1 px-5 py-2 text-neutral-500"
        >
          <GoEye size={15} />
          Review
        </button>
      ) : null}
    </div>
  );
};
