import { Link, useLocation, useParams } from "react-router-dom";
import { useMqrDetails } from "../../services/api";
import { Spinner } from "../../modules/commun";
import { MqrDetailsTemplate } from "../../modules/mqr/templates/mqr-details";
export const MqrDetails = () => {
  let { merchantId } = useParams();
  const location = useLocation();
  console.log(merchantId);
  const { data: mqr, isPending, isSuccess } = useMqrDetails(Number(merchantId));
  return (
    <div className=" md:py-10 py-6">
      {isPending ? (
        <div className=" flex-1 h-[90vh] flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <MqrDetailsTemplate mqr={mqr} />
        </div>
      )}
    </div>
  );
};
