import "./App.css";
import Router from "./routes";
import { hydrateAuth } from "./core/auth";
hydrateAuth();

function App() {
  return <Router />;
}

export default App;
