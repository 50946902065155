export const StatisticsCard = ({
  title,
  value,
}: {
  title: string;
  value: number;
}) => {
  return (
    <div className=" py-3 px-2 bg-white shadow-md shadow-black/10 flex flex-col gap-y-3 w-full max-w-[320px]">
      <div className=" text-neutral-600 text-sm">{title}</div>
      <div className=" text-primary-500 md:text-5xl text-3xl font-bold">
        {value}
      </div>
    </div>
  );
};
