import * as yup from "yup";
export const templateSchema = yup.object().shape({
  name: yup.string().required("Required"),
  approval_fee: yup.number().required("Required"),
  failure_fee: yup.number().required("Required"),
  refund_fee: yup.number().required("Required"),
  chargeback_fee: yup.number().required("Required"),
  daily_limit: yup.number().required("Required"),
  type: yup.string().required("Required"),
  global_min_transaction: yup.number().required("Required"),
  global_max_transaction: yup.number().required("Required"),
  kyc_requirement: yup.boolean(),
});
