import { useMutation } from "@tanstack/react-query";
import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";

export function useSendOffer() {
  return useMutation({
    // mutationFn: async (id) =>
    //   client.post(`admin/offers/send/${id}`, {
    //     timeout: 5000,
    //     headers: {
    //       Authorization: `Bearer ${getItem("auth")}`,
    //     },
    //   }),

    mutationFn: async (id) =>
      client({
        url: `admin/offers/send/${id}`,
        method: "POST",
        data: {},
        headers: {
          Authorization: `Bearer ${getItem("auth")}`,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log("error", error);
        }),
  });
}
