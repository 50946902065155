import { IoIosArrowBack } from "react-icons/io";
import { useOfferDetails } from "../../services/api";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Spinner } from "../../modules/commun";
import { Offer } from "../../core/types";
import { OfferDetailsTemplate } from "../../modules/offer-generation/templates/offer-details-template";
export const OfferDetails = () => {
  const { merchantId, merchantName } = useParams();
  const { data, isPending, refetch } = useOfferDetails(Number(merchantId));
  return (
    <div className="md:py-10 py-6">
      <div className=" flex gap-x-1 items-center">
        <Link
          to={"/offer-generation"}
          className=" bg-neutral-100 h-[30px] text-neutral-500 flex items-center justify-center rounded aspect-square"
        >
          <IoIosArrowBack size={18} />
        </Link>
        <div className=" font-bold text-2xl xs:text-4xl  whitespace-nowrap">
          Offer Details
        </div>
      </div>
      {isPending ? (
        <div className=" h-80 w-full flex justify-center items-end">
          <Spinner />
        </div>
      ) : (
        <>
          <OfferDetailsTemplate
            offer={data}
            refetch={refetch}
            merchant_name={merchantName ? merchantName : ""}
          />
        </>
      )}
    </div>
  );
};
