export const SearchInput = ({
  placeholder,
  value,
  items,
  onhandleChange,
}: {
  placeholder?: string;
  value: string;
  items?: Array<string>;
  onhandleChange: any;
}) => {
  return (
    <div className="">
      <input
        type="text"
        className=" w-full border border-neutral-300 rounded pl-3 text-neutral-600 py-2 text-sm outline-none"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onhandleChange(e.target.value)}
      />
    </div>
  );
};
