import { Link } from "react-router-dom";
import { OfferGenerationTemplate } from "../../modules/offer-generation/templates/offer-templates";
import { IoIosArrowBack } from "react-icons/io";
import { useParams } from "react-router-dom";

export const OfferTemplates = () => {
  let { merchantId } = useParams();
  console.log("merchant is", merchantId)
  return (
    <div className=" md:py-10 py-3">
      <div className="flex items-center gap-x-3 mb-6">
        <Link
          to={"/offer-generation"}
          className=" bg-neutral-100 h-[30px] text-neutral-500 flex items-center justify-center rounded aspect-square"
        >
          <IoIosArrowBack size={18} />
        </Link>
        <div className=" font-bold text-2xl xs:text-4xl">Offer Templates</div>
      </div>
      <OfferGenerationTemplate merchantId={merchantId} />
    </div>
  );
};
