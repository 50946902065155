import { Link, useNavigate } from "react-router-dom";
import { Currency, Offer } from "../../../../core/types";
import getDate from "../../../../core/utils/get-date";
import { useFiles } from "../../../../services/api";
import { useEffect, useState } from "react";
import { GoEye } from "react-icons/go";
import { OfferForm } from "../../components/offer-form";
import { OfferCurrencyCard } from "../../components/offer-details-currency-card";
import { DeleteOffer } from "../../components/delete-offer";
import { SendOffer } from "../../components/send-offer";

export const OfferDetailsTemplate = ({
  offer,
  merchant_name,
  refetch,
}: {
  offer: Offer;
  merchant_name: string;
  refetch: () => void;
}) => {
  const navigate = useNavigate();
  const [refetchNumber, setRefetchNumber] = useState(1);
  const { data, isPending, isSuccess } = useFiles(offer.user_id, refetchNumber);
  const [signedDoc, setSignedDoc] = useState<string>("");
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);

  useEffect(() => {
    let docs = null;
    if (isSuccess && data.length > 0) {
      docs = data.filter((file: any) => file.file.category == "document");
    }
    docs && docs[0] && setSignedDoc(docs[0].url);
  }, [data, isPending, refetchNumber]);
  const redirectFct = () => {
    navigate("/offer-generation");
  };
  return (
    <div className=" flex w-full flex-col items-center py-5">
      {offer?.eversign_status == "document_signed" ||
      offer?.eversign_status === "email_validation_waived" ? (
        <></>
      ) : (
        <div className=" flex items-center gap-x-4 justify-start my-3 max-w-[440px] w-full">
          <DeleteOffer id={offer?.id} redirect={redirectFct} />
          <SendOffer
            id={offer.user_id}
            refetch={refetch}
            status={offer.eversign_status}
          />
          <button
            onClick={() => setOpenUpdate(true)}
            className=" px-3  flex justify-center border-primary-500 border text-primary-500 text-xs rounded py-1 font-semibold"
          >
            Update
          </button>
        </div>
      )}
      <div className="flex flex-col w-full max-w-[440px] gap-y-1">
        <div className="py-1">
          <div className=" bg-primary-500 py-1 rounded-t-lg text-white  font-semibold text-xl text-center">
            {merchant_name}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            eversign status
          </div>
          <div
            className={`${
              offer.eversign_status == "document_signed" ||
              offer.eversign_status === "email_validation_waived"
                ? " text-primary-500"
                : " text-secondary-500"
            }  text-sm capitalize`}
          >
            {offer.eversign_status == "document_signed" ||
            offer.eversign_status === "email_validation_waived" ? (
              <Link
                target="_blank"
                to={signedDoc}
                className=" flex items-center gap-x-2 "
              >
                Document Signed
                <GoEye />
              </Link>
            ) : offer.eversign_status == "document_created" ? (
              "document sent"
            ) : (
              "document pending"
            )}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">Date</div>
          <div className={` text-sm text-neutral-600`}>
            {getDate(offer.created_at)}
          </div>
        </div>
        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">Type</div>
          <div className={` text-sm text-neutral-600`}>{offer.type}</div>
        </div>

        <div className="py-1 mt-2">
          <div className=" bg-primary-500 py-1 rounded-t-lg text-white  font-semibold text-xl text-center">
            {"FTD Buy Rates Global Cards"}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Minimum Transaction
          </div>
          <div className={` text-sm text-neutral-600`}>
            {offer.global_min_transaction}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Maximum Transaction
          </div>
          <div className={` text-sm text-neutral-600`}>
            {offer.global_max_transaction}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Approved Transaction Fee
          </div>
          <div className={` text-sm text-neutral-600`}>
            ${offer.approval_fee}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Declined Transaction Fee
          </div>
          <div className={` text-sm text-neutral-600`}>
            ${offer.failure_fee}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Refund Fee
          </div>
          <div className={` text-sm text-neutral-600`}>${offer.refund_fee}</div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Chargeback Fee
          </div>
          <div className={` text-sm text-neutral-600`}>
            ${offer.chargeback_fee}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Daily Limit
          </div>
          <div className={` text-sm text-neutral-600`}>
            ${offer.daily_limit}
          </div>
        </div>

        {offer.currency_params.map(
          (currency: Currency) =>
            currency.type === "wire" && (
              <OfferCurrencyCard key={currency.id} currency={currency} />
            )
        )}
        {offer.currency_params.map(
          (currency: Currency) =>
            currency.type === "card" && (
              <OfferCurrencyCard key={currency.id} currency={currency} />
            )
        )}

        {openUpdate && (
          <div className="fixed top-0 left-0 h-full w-full bg-black/40 px-3 flex items-center justify-center">
            <OfferForm
              refetch={() => refetch()}
              update={true}
              merchant_id={Number(offer.user_id)}
              onConfirm={() => console.log()}
              selected_template={offer}
              onCancel={() => setOpenUpdate(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
