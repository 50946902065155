export const types = [
  {
    id: 1,
    value: "card",
    label: "card",
  },
  {
    id: 2,
    value: "wire",
    label: "wire",
  },
];
