import { MerchantsTemplate } from "../../modules/marchants/templates/merchants";

export const OfferGeneration = () => {
  return (
    <div className=" md:py-10 py-3">
      <div className=" font-bold text-2xl xs:text-4xl mb-6">
        Offer Generation
      </div>
      <MerchantsTemplate is_offer_generation={true} />
    </div>
  );
};
