export const currencies_list = [
  {
    id: 1,
    label: "USD",
    value: "USD",
  },
  {
    id: 2,
    label: "EUR",
    value: "EUR",
  },
  {
    id: 3,
    label: "GBP",
    value: "GBP",
  },
  {
    id: 4,
    label: "AED",
    value: "AED",
  },
  {
    id: 5,
    label: "JPY",
    value: "JPY",
  },
];
