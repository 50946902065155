import { useFormik } from "formik";
import { currenciesSchema } from "../../../../validations/currencies-validation";
import { FormButton, FormInput, FormSelect } from "../../../commun";
import { currencies_list, types } from "../../../../core/constants";
import { useState } from "react";
import { Currency } from "../../../../core/types";

export const CurrenciesForm = ({
  currency,
  index,
  onHandleSubmit,
  onRemoveItem,
  existedCard,
  existedWire,
}: {
  currency: Currency;
  onHandleSubmit: (values: Currency, index: number) => void;
  index: number;
  onRemoveItem: (id: number | undefined) => void;
  existedCard?: Array<string>;
  existedWire?: Array<string>;
}) => {
  const [showApprove, setShowApprove] = useState(false);
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      mdr: currency.mdr,
      code: currency.code,
      type: currency.type,
      rolling_reserve_amount: currency.rolling_reserve_amount,
      rolling_reserve_percent: currency.rolling_reserve_percent,
      rolling_reserve_duration: currency.rolling_reserve_duration,
      settlement_fee: currency.settlement_fee,
      settlement_cycle: currency.settlement_cycle,
    },
    validationSchema: currenciesSchema,
    onSubmit: (values) => {
      setShowApprove(false);
      let newValues = {
        id: currency.id,
        ...values,
      };
      onHandleSubmit(newValues as Currency, index);
    },
  });
  return (
    <form
      className="flex flex-col gap-y-4 md:w-96 w-[320px] border rounded p-2  border-primary-500/50 pb-4 mt-3"
      onSubmit={handleSubmit}
    >
      <FormSelect
        placeholder={"Select an option"}
        options={types}
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"text"}
        label={"Type"}
        id="type"
        value={values.type}
        errors={errors.type}
        touched={touched.type}
      />
      <FormSelect
        placeholder={
          values.type == "" ? "Select a type first" : "Select an option"
        }
        options={currencies_list}
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"text"}
        label={"Currency"}
        id="code"
        value={values.code}
        errors={errors.code}
        touched={touched.code}
        disabled={values.type == ""}
        // disabledCondition={values.type === "wire" ? existedWire : existedCard}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"number"}
        label={"MDR (Merchant Discount Rate)"}
        id="mdr"
        value={values.mdr}
        errors={errors.mdr}
        touched={touched.mdr}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"number"}
        label={"Rolling Reserve Cap"}
        id="rolling_reserve_amount"
        value={values.rolling_reserve_amount}
        errors={errors.rolling_reserve_amount}
        touched={touched.rolling_reserve_amount}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"number"}
        label={"Rolling reserve percent"}
        id="rolling_reserve_percent"
        value={values.rolling_reserve_percent}
        errors={errors.rolling_reserve_percent}
        touched={touched.rolling_reserve_percent}
      />

      <FormInput
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"number"}
        label={"Rolling reserve duration"}
        id="rolling_reserve_duration"
        value={values.rolling_reserve_duration}
        errors={errors.rolling_reserve_duration}
        touched={touched.rolling_reserve_duration}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"number"}
        label={"Settlement Fee "}
        id="settlement_fee"
        value={values.settlement_fee}
        errors={errors.settlement_fee}
        touched={touched.settlement_fee}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"number"}
        label={"Settlement Cycle"}
        id="settlement_cycle"
        value={values.settlement_cycle}
        errors={errors.settlement_cycle}
        touched={touched.settlement_cycle}
      />
      <div className="flex w-full justify-between gap-x-1">
        <button
          type="button"
          onClick={() => onRemoveItem(currency.id)}
          className=" rounded bg-500 bg-red-400 whitespace-nowrap  w-1/2 px-3 py-2 text-white text-sm"
        >
          Delete this section
        </button>
        <FormButton
          type={"submit"}
          title="Update"
          isLoding={false}
          disabled={!showApprove}
        />
      </div>
    </form>
  );
};
