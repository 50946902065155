import { useDeleteOffer } from "../../../../services/api";
import { Spinner } from "../../../commun";
export const DeleteOffer = ({ id, refetch, redirect }: any) => {
  const { mutateAsync: mutateAsyncDelete, isPending: isPendingDelete } =
    useDeleteOffer();

  const deleteOffer = async (id: any) => {
    await mutateAsyncDelete(id, {
      onSuccess: (response) => {
        // console.log("response ", response);
        refetch && refetch();
        redirect && redirect();
      },
      onError: (error) => {
        console.log("from ", error);
      },
    }).catch((error) => console.log());
  };
  return (
    <button
      onClick={() => deleteOffer(id)}
      className=" px-3 w-16 flex justify-center border-red-600 border text-red-600 text-xs rounded py-1 font-semibold"
    >
      {isPendingDelete ? <Spinner /> : "Delete"}
    </button>
  );
};
