import { IoMdClose } from "react-icons/io";
import { GoEye } from "react-icons/go";
import { BiSolidEdit } from "react-icons/bi";
import { CiMail } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import getDate from "../../../../core/utils/get-date";
import { StepsProgress } from "../circle-progress";
import { Steps } from "../merchant-status";

export const MerchantModal = ({ merchant, close }: any) => {
  console.log(merchant);
  const navigate = useNavigate();
  return (
    <div className="fixed top-0 left-0 h-full w-full bg-black/40 flex items-center justify-center">
      <div className="bg-white rounded gap-y-2 py-4 px-3 flex flex-col w-full max-w-[390px] shadow-sm shadow-black/40">
        <div className=" flex w-full justify-between items-center">
          <div className=" text-base md:text-lg font-bold text-neutral-900">
            {merchant.name}
          </div>
          <button onClick={close}>
            <IoMdClose size={20} className=" text-neutral-600" />
          </button>
        </div>
        <div className=" text-neutral-500 text-sm md:pr-4">
          Make changes to your profile here. Click save when you're done.
        </div>

        <StepsProgress
          mqr={merchant.mqr}
          kyb={merchant.files.length === 4}
          offer_signed={merchant.offer}
        />

        <div className="flex bg-neutral-100 gap-x-4 xs:gap-x-12 justify-between rounded py-3 px-3">
          <div className="flex flex-col gap-y-2">
            <div>
              <div className=" text-neutral-600 text-sm">Company name</div>
              <div className=" text-neutral-900 text-sm xs:text-base">
                {merchant.mqr ? merchant.mqr.companyName : "..."}
              </div>
            </div>
            <div>
              <div className=" text-neutral-600 text-sm">Created on</div>
              <div className=" text-neutral-900 text-sm xs:text-base">
                {getDate(merchant.created_at)}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2 md:pr-12 justify-between">
            <div>
              <div className=" text-neutral-600 text-sm">Status</div>
              <div className=" text-sm xs:text-sm text-secondary-500">
                {merchant.mqr ? "Submitted" : "Not submitted"}
              </div>
            </div>
            <div>
              <div className=" text-neutral-600 text-sm">Last update on</div>
              <div className=" text-neutral-900 text-sm xs:text-base">
                {getDate(merchant.updated_at)}
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full mt-4">
          <Steps
            mqr={merchant.mqr}
            kyb={merchant.files.length === 4}
            offer_signed={merchant?.offer !== null}
            mqr_data={merchant.mqr}
            id={merchant.id}
          />
          <div className=" flex-1 flex justify-end items-start">
            <div>
              <div className="flex justify-between gap-x-1">
                <Link
                  to={`/merchant-details/${merchant.id}`}
                  className="bg-neutral-100 py-1 rounded items-center  px-3 flex gap-x-1 text-neutral-600"
                >
                  <GoEye size={13} className=" " />
                  <div>View</div>
                </Link>
                <button className=" bg-neutral-100 py-1 rounded items-center  px-3 flex gap-x-1 text-neutral-600">
                  <BiSolidEdit size={14} />
                  <div className=" text-sm ">Edit</div>
                </button>
              </div>
              <button className=" bg-neutral-100 py-1 w-full justify-center mt-1 rounded items-center px-3 flex gap-x-1 text-neutral-600">
                <CiMail size={16} />
                <div className=" text-sm ">Send reminder</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
