export const FormSelect = ({
  handleChange,
  errors,
  touched,
  handleBlur,
  label,
  login,
  options,
  disabledCondition,
  ...otherProps
}: any) => {
  return (
    <div className="  max-w-full ">
      {label ? (
        <div className=" text-neutral-900 mb-1 text-sm font-medium whitespace-nowrap">
          {label}
        </div>
      ) : (
        <div className="h-5 mb-1"></div>
      )}

      <select
        onChange={handleChange}
        onBlur={handleBlur}
        name=""
        id=""
        {...otherProps}
        className={`h-[40px] w-full border px-4 outline-none rounded  ${
          errors && touched ? "border-red-600" : "border-neutral-300"
        } text-sm`}
      >
        {otherProps.placeholder && (
          <option disabled selected value="">
            {otherProps.placeholder}
          </option>
        )}
        {options.map((option: any, index: number) => (
          <option
            disabled={
              disabledCondition
                ? disabledCondition.indexOf(option.value) > -1
                : false
            }
            value={option.value}
            id={option.value}
            key={index}
          >
            {option.value}
          </option>
        ))}
      </select>
      {errors && touched && (
        <div className="text-red-600 text-xs mt-1">{errors}</div>
      )}
    </div>
  );
};
