import {
  FormInput,
  FormButton,
  FormMultiSelect,
  FormSelect,
} from "../../../commun";
import { useFormik } from "formik";
import { generateOfferSchema } from "../../../../validations/generate-offer-validation";
import { types } from "../../../../core/constants";
import { useCreatedOffer, useUpdateOffer } from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import { CurrenciesForm } from "../currencies-form";
import { useEffect, useState } from "react";
import { Currency } from "../../../../core/types";
import { IoCloseOutline } from "react-icons/io5";
import { ConfirmPopup } from "../../../commun/confirm-popup";

export const OfferForm = ({
  selected_template,
  onConfirm,
  onCancel,
  merchant_id,
  update,
  refetch,
}: {
  selected_template: any;
  onConfirm: any;
  onCancel: any;
  merchant_id: number;
  update?: any;
  refetch?: any;
}) => {
  const {
    mutateAsync,
    isPending,
    isSuccess: isSuccessCreate,
    isError: isErrorCreate,
  } = useCreatedOffer();
  const {
    mutateAsync: asyncUpdate,
    isPending: isPendingUpdate,
    isSuccess: isSuccessUpdate,
    isError: isErrorUpdate,
  } = useUpdateOffer();

  const navigate = useNavigate();

  const [currencies, setCurrencies] = useState<Array<Currency>>([]);
  const [existedCard, setExistedCard] = useState<Array<any>>([]);
  const [existedWire, setExistedWire] = useState<Array<any>>([]);
  const [newSections, setNewSections] = useState<Array<number | undefined>>([]);
  const [offerUpdated, setOfferUpdated] = useState<boolean>(false);

  useEffect(() => {
    let newCurrencies: Array<Currency> = [];
    selected_template.currency_params.map(
      (currency: Currency, index: number) => {
        delete currency.created_at;
        delete currency.currencyable_id;
        delete currency.currencyable_type;
        delete currency.updated_at;
        newCurrencies.push({ ...currency });
      }
    );
    setCurrencies([...newCurrencies]);
  }, []);

  const sendOffer = async (values: any) => {
    const offer_to_send = {
      user_id: merchant_id,
      ...values,
    };
    delete offer_to_send.rolling_reserve_percent_global;
    await mutateAsync(offer_to_send, {
      onSuccess: (response) => {
        onCancel();
        navigate("offer-sent");
      },
      onError: (error) => {
        // console.log("from login", error);
      },
    }).catch((error) => console.log());
  };

  const updateOffer = async (values: any) => {
    const offer_to_send = {
      ...values,
    };
    delete offer_to_send.rolling_reserve_percent_global;
    await asyncUpdate(offer_to_send, {
      onSuccess: (response) => {
        setOfferUpdated(true);
        refetch();
        onCancel();
      },
      onError: (error) => {
        // console.log("from login", error);
      },
    }).catch((error) => console.log());
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      approval_fee: selected_template.approval_fee,
      failure_fee: selected_template.failure_fee,
      refund_fee: selected_template.refund_fee,
      chargeback_fee: selected_template.chargeback_fee,
      type: selected_template.type,
      daily_limit: selected_template.daily_limit,
      global_min_transaction: selected_template.global_min_transaction,
      global_max_transaction: selected_template.global_max_transaction,
      kyc_requirement: selected_template.kyc_requirement,
    },
    validationSchema: generateOfferSchema,
    onSubmit: (values) => {
      //   login(values);
      let newCurrencie = currencies.map((currency) => {
        // delete currency.id;
        return currency;
      });
      const payload = {
        user_id: merchant_id,
        metadata: null,
        ...values,
        currencies: newCurrencie,
      };
      // delete payload.currency_params
      if (update) {
        updateOffer(payload);
        console.log("update");
      } else {
        sendOffer(payload);
        console.log("create");
      }
    },
  });

  const currenciesUpdate = (item: any, index: number) => {
    let newItems = currencies;
    let newItem = { ...newItems[index] };
    newItem = item;
    newItems[index] = newItem;
    setCurrencies([...newItems]);
    setNewSections((oldValues) =>
      oldValues.filter((section_id) => section_id !== item.id)
    );
  };

  const addCurrencySection = () => {
    let last_id: number | undefined = 0;
    if (currencies.length > 0) {
      last_id = currencies[currencies.length - 1].id;
    } else {
      last_id = 1;
    }

    let newCurrency: Currency = {
      id: last_id && last_id + 1,
      code: "",
      type: "",
      mdr: 0,
      rolling_reserve_amount: 0,
      rolling_reserve_percent: 0,
      rolling_reserve_duration: 0,
      settlement_fee: 0,
      settlement_cycle: 0,
    };
    setCurrencies([...currencies, newCurrency]);
    setNewSections([...newSections, newCurrency.id]);
  };
  const removeCurrency = (id: number | undefined) => {
    setCurrencies((oldValues) =>
      oldValues.filter((currency) => currency.id !== id)
    );

    setNewSections((oldValues) =>
      oldValues.filter((section_id) => section_id !== id)
    );
  };
  return (
    <div className=" flex flex-col gap-y-4 min-w-[300px] y-global-bg md:min-w-[400px] bg-white p-5 rounded-lg max-h-[90vh] overflow-y-scroll">
      <form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
        <div className="flex justify-between gap-x-3">
          <div className="text-2xl text-neutral-900 font-bold">
            {selected_template.name} template
          </div>
          <button onClick={onCancel}>
            <IoCloseOutline size={18} className=" text-neutral-900" />
          </button>
        </div>

        <FormSelect
          placeholder={"Select an option"}
          options={types}
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"text"}
          label={"Type"}
          id="type"
          value={values.type}
          errors={errors.type}
          touched={touched.type}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Min transaction"}
          id="global_min_transaction"
          value={values.global_min_transaction}
          errors={errors.global_min_transaction}
          touched={touched.global_min_transaction}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Limit size per transaction"}
          id="global_max_transaction"
          value={values.global_max_transaction}
          errors={errors.global_max_transaction}
          touched={touched.global_max_transaction}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Approved Transaction Fee"}
          id="approval_fee"
          value={values.approval_fee}
          errors={errors.approval_fee}
          touched={touched.approval_fee}
        />
        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Declined Transaction Fee"}
          id="failure_fee"
          value={values.failure_fee}
          errors={errors.failure_fee}
          touched={touched.failure_fee}
        />
        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Refund Fee (Per transaction)"}
          id="refund_fee"
          value={values.refund_fee}
          errors={errors.refund_fee}
          touched={touched.refund_fee}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Chargeback Fees - Dispute (Per dispute)"}
          id="chargeback_fee"
          value={values.chargeback_fee}
          errors={errors.chargeback_fee}
          touched={touched.chargeback_fee}
        />

        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          type={"number"}
          label={"Daily limit"}
          id="daily_limit"
          value={values.daily_limit}
          errors={errors.daily_limit}
          touched={touched.daily_limit}
        />
      </form>

      <div className="mt-3 font-bold text-neutral-900 text-lg">
        Buy Rates for Card Processing
      </div>
      {currencies.map((currency: any, index: number) => (
        <CurrenciesForm
          onHandleSubmit={(item: any, i: number) => currenciesUpdate(item, i)}
          key={currency.id}
          index={index}
          currency={currency}
          onRemoveItem={(i) => removeCurrency(i)}
          existedCard={existedCard}
          existedWire={existedWire}
          // setDisabledButton={()=> setIsDisabled(false)}
        />
      ))}

      <div className="flex">
        <FormButton
          type={"submit"}
          title="Add New Currency"
          isLoding={false}
          disabled={false}
          onClick={addCurrencySection}
        />
      </div>

      <div className="flex justify-between items-center w-full gap-x-4">
        <button
          type="button"
          onClick={onCancel}
          className=" rounded border border-red-500 bg-white px-6 py-2 text-red-500"
        >
          Cancel
        </button>
        <FormButton
          type={"submit"}
          title={update ? "Update offer" : "Create offer"}
          isLoding={isPending || isPendingUpdate}
          disabled={newSections.length > 0 || isPending || isPendingUpdate}
          onClick={handleSubmit}
        />
      </div>
      {offerUpdated && (
        <ConfirmPopup
          title="Offer updated successfully"
          confirmButtonTitle="Done"
          onConfirm={() => setOfferUpdated(false)}
        ></ConfirmPopup>
      )}
    </div>
  );
};
